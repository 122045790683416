import React, { useEffect } from "react";
import { Router } from "@reach/router";
import {
  HomePage,
  ContactUsPage,
  DynamicPage,
  AboutUsPage,
  aboutUsTemplatesVersionVariantsTypes,
  contactUsTemplatesVersionVariantsTypes,
  dynamicTemplatesVersionVariantsTypes,
} from "@homecu/blossom-websites-core-package";

import templateVariantSettings from "config";

// import { HOMEPAGE_DATA_MODEL } from "data/cms-models";

function IndexPage() {
  // const footerFirstLogoOnClick = () => {
  //   if (!window || !window.document || !document) {
  //     setTimeout(footerFirstLogoOnClick, 350);

  //     return;
  //   }

  //   const footerFirstLogo =
  //     document.querySelector(".FOOTER__BUSINESS_LOGO:first-child") ||
  //     document.querySelector(
  //       "footer > div:first-child > *:nth-child(2) > *:nth-child(3) > *:nth-child(1) > *:nth-child(1)",
  //     );

  //   if (!footerFirstLogo) {
  //     setTimeout(footerFirstLogoOnClick, 350);

  //     return;
  //   }

  //   footerFirstLogo.addEventListener("click", () => window.open("https://www.ncua.gov", "_blank"));
  // };

  useEffect(() => {
    // setTimeout(footerFirstLogoOnClick, 2500);
  }, []);

  const commonProps = {
    // Configuracion inicial de la plantilla
    config: templateVariantSettings,
    // Habilitar o Desabilitar background de tipo sombra al abrir el menu de navegacion
    // backgroundScreenHeader: false,
    //  Configuracion personalizada del Navigation Bar (Menu de Navegacion)
    navigationMenuOptions: {
      // Cambiar la altura del sub-menu de navegacion
      // dropdownSeparationFromBottom: 10,
    },
    // Personalizar Footer
    footerOptions: {
      // Cambia la imagen y le da la funcionalidad speedbump al logo de ncua
      businessCustomLogos: [
        {
          replaceByIndex: 0,
          url: "https://olb-assets.s3.us-east-2.amazonaws.com/images/NCUA_Logo.svg",
          hash: "NCUA Logo",
          invert: true,
          linkTo: "https://www.ncua.gov",
        },
      ],
    },
  };

  return (
    <Router>
      <HomePage path="/" config={templateVariantSettings} {...commonProps} />

      <AboutUsPage
        variant={aboutUsTemplatesVersionVariantsTypes.ABOUT_US_TEMPLATE_V7}
        path="/about-us"
        config={templateVariantSettings}
        {...commonProps}
      />

      <ContactUsPage
        path="/contact-us"
        variant={contactUsTemplatesVersionVariantsTypes.CONTACT_US_TEMPLATE_V8}
        config={templateVariantSettings}
        {...commonProps}
      />

      <DynamicPage
        variant={dynamicTemplatesVersionVariantsTypes.DYNAMIC_TEMPLATE_V7}
        path="/:id"
        config={templateVariantSettings}
        {...commonProps}
      />
    </Router>
  );
}

export default IndexPage;
